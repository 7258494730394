<template>
    <img class="c-pattern" :id="'pattern-' + comp.settings.img._id" :src="siteURL + comp.settings.img.path" :class="comp.settings.class" :alt="comp.settings.img.alt || 'pattern'">
</template>

<script>
  import { parallaxItem } from '@/mixins/parallax'

  export default {
    name: 'Comp_pattern',
    mixins: [parallaxItem],
    props: {
      comp: Object
    },

    data() {
      return {
        siteURL: null,
        parallax: {
          item: `#pattern-${this.comp.settings.img._id}`,
          triggerElement: `#pattern-${this.comp.settings.img._id}`,
          animFrom: '30%',
          animTo: '-30%'
        }
      }
    },

    created() {
      this.siteURL = process.env.VUE_APP_UPLOADS_URL
    },
  }
</script>
