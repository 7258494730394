export const parallaxItem = {

  data () {
    return {
      parallax: {
        item: null,
        duration: '180%',
        animFrom: '0',
        animTo: '-10%',
        triggerElement: null
      }
    }
  },

  mounted () {
    this.$nextTick(this.parallaxItem)
  },

  methods: {
    parallaxItem () {
      const tl = new this.$gsap.TimelineMax()

      tl.fromTo(this.parallax.item, 1, {y: this.parallax.animFrom}, {y: this.parallax.animTo, ease: this.$gsap.Power1.easeInOut })

      const scene = new this.$scrollmagic.Scene({
        triggerElement: this.parallax.triggerElement,
        triggerHook: 'onEnter',
        duration: this.parallax.duration
      })
          .setTween(tl)

      this.$ksvuescr.$emit('addScene', 'parallaxItem', scene)
    }
  },

  destroyed () {
    const vm = this
    if(vm.controller === null) vm.$ksvuescr.$emit('destroy')
  }
}
